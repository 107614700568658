import './AddStyle.css'

import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import NaviBar from "../../Components/NaviBar/NaviBar";
import Selection from "../../Components/Selection/Selection";
import DateSelection from '../../Components/DateSelection/DateSelection';
import NumPad from '../../Components/NumPad/NumPad';
import { makeApiRequest } from '../../lib/utils';

function Add() {

   const navigate = useNavigate();

   const [categories, setCategories] = useState<{ name: string, id: number }[]>();

   const [categoryId, setCategoryId] = useState<number | undefined>()
   const [amount, setAmount] = useState<number>(0);
   const [date, setDate] = useState<string | undefined>(undefined);

   var ismakingRequest = useRef(false);

   useEffect(() => {
      fetchCategories();
   }, [1])

   return (
      <div className='Add'>
         <NaviBar />
         <div className="Add-Selections">
            <Selection title="Category" options={categories} onChange={setCategoryId} />
         </div>
         <h1>{amount.toFixed(2) + '€'}</h1>
         <DateSelection handleChange={setDate} />
         <NumPad onValueChange={setAmount} />
         <div>
            <button className='primary btnB' onClick={add}>Add</button>
         </div>
      </div>
   )

   async function add() {
      if (amount <= 0 || categoryId === undefined || ismakingRequest.current) return console.log('Amount or CategoryId wrong', ismakingRequest.current);

      ismakingRequest.current = true;
      const body = {
         category_id: categoryId,
         amount: amount.toFixed(2),
         ...(date && { date: date })
      }

      const response = await makeApiRequest('/Add/API/AddTurnover', 'POST', body);

      if (response.status === 403 || response.status === 401) {
         return navigate('/');
      }
      if (response.status === 200) {
         ismakingRequest.current = false;
         navigate('/Home');
      } else {
         ismakingRequest.current = false;
         alert(response.statusText);
      }
   }

   async function fetchCategories() {
      const response = await makeApiRequest("/Add/API/GetCategories", 'GET');
      if (response.status === 403 || response.status === 401) {
         return navigate('/');
      }
      if (response.status === 200) {
         const data: any = await response.json();
         setCategories(data)
      }
   }
};

export default Add;