import './SimpleButtonGroupStyle.css'

import SimpleButton from "../SimpleButton/SimpleButton";
import { useState } from 'react';

interface SimpleButtonGroupProps {
   buttons: { title: string, val?: any }[];
   active?: number
   handleClick: (value: any) => void
}

function SimpleButtonGroup({ buttons, active, handleClick }: SimpleButtonGroupProps) {

   if (active === undefined || active < 0 || active >= buttons.length) {
      active = 0;
   }

   const [activeButton, setActiveButton] = useState(active);

   function SimpleButtonClick(index: number) {
      setActiveButton(index);
      const currBtn = buttons[index];
      handleClick(currBtn.val || currBtn.title);
   }

   const btnColl = buttons.map((btn, index) => (
      <SimpleButton
         key={index}
         handleClick={SimpleButtonClick}
         title={btn.title}
         value={index}
         active={activeButton === index}
      />
   ));

   return (
      <div className='SimpleButtonGroup'>
         {btnColl}
      </div>
   )
};

export default SimpleButtonGroup;
