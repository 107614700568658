import { useLocation, useNavigate } from 'react-router-dom';
import './NaviBarStyle.css'

function NaviBar() {

   const navigate = useNavigate();
   const active = useLocation().pathname.substring(1);

   function handleInnerClick(page: string) {
      navigate(`/${page}`)
   }

   return (
      <div className='NaviBar'>
         <img
            key={0}
            className={'NaviButton'}
            // %PUBLIC%/
            src={`assets/home${active === 'Home' ? 'A' : ''}.svg`}
            alt='Home'
            onClick={() => handleInnerClick('Home')}
         />
         <img
            key={1}
            className={'NaviButton'}
            src={`/assets/add${active === 'Add' ? 'A' : ''}.svg`}
            alt='Add'
            onClick={() => handleInnerClick('Add')}
         />
         {/* <img
            key={2}
            className={'NaviButton'}
            src={`/assets/time${active === 'Track' ? 'A' : ''}.svg`}
            alt='Time'
            onClick={() => handleInnerClick('Track')}
         />
         */}
         <img
            key={3}
            className={'NaviButton'}
            src={`/assets/edit${active === 'Edit' ? 'A' : ''}.svg`}
            alt='Edit'
            onClick={() => handleInnerClick('Edit')}
         />
         {/*
         <img
            key={4}
            className={'NaviButton'}
            src={`/assets/settings${active === 'Settings' ? 'A' : ''}.svg`}
            alt='Settings'
            onClick={() => handleInnerClick('Settings')}
         /> */}
      </div>
   )
};

export default NaviBar;
