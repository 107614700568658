import './InfoCardListStyle.css'

import InfoCard from "./InfoCard";

interface InfoCardListProps {
   content: string[][] | { [key: string]: string | number }[];
   align: 's' | 'c' | 'e' | 'se' | 'sc' | 'ce' | 'sce';
   spaceing?: 1 | 2 | 3;
}

function InfoCardList({ content, align = 's', spaceing = 3 }: InfoCardListProps) {
   const cards = content.map((elm, index) => {
      if (Array.isArray(elm)) {
         return <InfoCard key={index} align={align} content={elm} spaceing={spaceing} />;
      } else if (typeof elm === 'object') {
         const objValues = Object.values(elm);
         return <InfoCard key={index} align={align} content={objValues} spaceing={spaceing} />;
      }
      return null;
   });

   return (
      <div className='InfoCardList'>
         {cards}
      </div>
   );
}

export default InfoCardList;