import './BarInfoCardStyle.css'

interface BarInfoCardProps {
   content: string[]
   align: 's' | 'c' | 'e' | 'se' | 'sc' | 'ce' | 'sce'
   progress: number
   spaceing?: 1 | 2 | 3

}

function BarInfoCard({ spaceing = 3, content, align = 's', progress }: BarInfoCardProps) {

   var labels;

   if (spaceing === 1) {
      labels = content.map((elm, index) => (
         <p key={index} className={`InfoCard-align-${align} s${spaceing}`} > {String(elm)}</p>
      ));
   } else {
      labels = content.map((elm, index) => (
         <h4 key={index} className={`InfoCard-align-${align} s${spaceing}`} > {String(elm)}</h4 >
      ));
   }

   return (
      <div className={`BarInfoCard s${spaceing}`}>
         <div className="prosses" style={{ width: 100 * progress + "%", backgroundColor: percentToRGB(progress) }}></div>
         <div className={`content s${spaceing}`}>
            {labels}
         </div>
      </div>
   );

   function percentToRGB(percent: number) {
      percent = Math.max(0, Math.min(1, percent));
      let r, g, b;
      if (percent <= 0.3) {
         const green = { r: 58, g: 196, b: 76 };
         r = green.r;
         g = green.g;
         b = green.b;
      } else if (percent <= 0.5) {
         const green = { r: 58, g: 196, b: 76 };
         const yellow = { r: 243, g: 189, b: 53 };
         const ratio = (percent - 0.4) / 0.1;
         r = Math.round(green.r + ratio * (yellow.r - green.r));
         g = Math.round(green.g + ratio * (yellow.g - green.g));
         b = Math.round(green.b + ratio * (yellow.b - green.b));
      } else {
         const yellow = { r: 243, g: 189, b: 53 };
         const red = { r: 245, g: 83, b: 71 };
         const ratio = (percent - 0.5) / 0.5;
         r = Math.round(yellow.r + ratio * (red.r - yellow.r));
         g = Math.round(yellow.g + ratio * (red.g - yellow.g));
         b = Math.round(yellow.b + ratio * (red.b - yellow.b));
      }
      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
   }
};

export default BarInfoCard;
