import { useState, useEffect } from 'react';

export function useLoadOrDefault<T>(key: string, standard: T) {

   const [value, setValue] = useState<T>(() => {

      const storageString = localStorage.getItem(key);
      
      if (storageString) {
         try {
            return JSON.parse(storageString);
         } catch (e) {
            return storageString;
         }
      }
      return standard;
   });

   useEffect(() => {
      if (typeof value === 'object') {
         localStorage.setItem(key, JSON.stringify(value));
      } else {
         localStorage.setItem(key, String(value));
      }
   }, [key, value]);

   return [value, setValue] as const;
}
