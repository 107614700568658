import './InfoMessageStyle.css';
import { ReactSVG } from 'react-svg';

interface InfoMessageProps {
   icon?: 'alert' | 'info' | 'network',
   iconColor?: 'red',
   message: string
   pressedClose: () => void
}

function InfoMessage({ icon = 'info', message, pressedClose, iconColor }: InfoMessageProps) {
   return (
      <div className='InfoMessage'>
         <ReactSVG src={`assets/${icon}.svg`} className={`icon ${iconColor || ''}`} />
         <p>
            {message}
         </p>
         <ReactSVG src="assets/close.svg" className="close" onClick={pressedClose} />
      </div>
   )
};

export default InfoMessage;