import './EditStyle.css'

import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CategoriesT, EditedCategoryT, EditedTurnoverT, TurnoversT } from '../../lib/types';
import { getLastMonth, makeApiRequest } from '../../lib/utils';

import EditCategoryInfoCard from '../../Components/InfoCard/EditCategoryInfoCard';
import Selection from '../../Components/Selection/Selection';
import NaviBar from "../../Components/NaviBar/NaviBar";
import EditTurnoverInfoCard from '../../Components/InfoCard/EditTurnoverInfoCard';
import EditCategoryCard from '../../Components/EditCard/EditCategoryCard';
import EditTurnoverCard from '../../Components/EditCard/EditTurnoverCard';
import InfoMessage from '../../Components/InfoMessage/InfoMessage';

function Edit() {

   const navigate = useNavigate();

   const [categories, setCategories] = useState<CategoriesT[]>([]);
   const [categoryInEdit, setCategoryInEdit] = useState<CategoriesT | undefined>()

   const [turnoverCategories, setTurnoverCategories] =
      useState<{ name: string, id: number }[] | undefined>(undefined);
   const [turnoverStartDate, setTurnoverStartDate] = useState(getLastMonth());
   const [turnoverEndDate, setTurnoverEndDate] = useState(new Date().toISOString().slice(0, 10));
   const [turnoverCategory, setTurnoverCategory] = useState<number>(-1);

   const [turnoverEntries, setTurnoverEntries] = useState<TurnoversT[] | undefined>();

   const [turnoverInEdit, setTurnoverInEdit] = useState<TurnoversT | undefined>();

   const [infoMessage, setInfoMessage] = useState<JSX.Element>();


   useEffect(() => {
      fetchCategories();
   }, [1]);

   useEffect(() => {
      setTurnoverCategories([
         { name: "All", id: -1 },
         ...categories.map(category => ({
            name: category.name,
            id: category.id
         }))
      ]);
   }, [categories])

   useEffect(() => {
      fetchTurnovers()
   }, [turnoverCategory, turnoverEndDate, turnoverStartDate]);

   return (
      <div className='Edit'>
         <NaviBar />
         {infoMessage}

         <h3>Categories</h3>
         {categories.map((cat, index) => (
            <EditCategoryInfoCard key={index} details={cat} handleClick={setCategoryInEdit} />
         ))
         }
         {categoryInEdit &&
            <>
               <EditCategoryCard
                  editData={categoryInEdit}
                  editsMade={makeCategoryEdit}
               />
               <div className="overlay" onClick={() => setCategoryInEdit(undefined)}></div>
            </>
         }

         <h3>Turnovers</h3>
         <div className="turnover_filter">
            <Selection
               title='Category'
               onChange={setTurnoverCategory}
               options={turnoverCategories}
            />
            <div className="turnover_dates_filter">
               <input type="date"
                  placeholder='date'
                  value={turnoverStartDate}
                  onChange={(e) => setTurnoverStartDate(e.currentTarget.value)} />
               <img src='assets/arrow-right.svg' alt='to' />
               <input type="date"
                  placeholder='date'
                  value={turnoverEndDate}
                  onChange={(e) => setTurnoverEndDate(e.currentTarget.value)} />
            </div>
         </div>

         {
            turnoverEntries &&
            <EditTurnoverInfoCard
               details={turnoverEntries}
               handleClick={setTurnoverInEdit}
            />
         }
         {turnoverInEdit &&
            <>
               <EditTurnoverCard
                  editData={turnoverInEdit}
                  categoryList={categories}
                  deleteTurnover={deleteTurnover}
                  editsMade={makeTurnoverEdit}
               />
               <div className="overlay" onClick={() => setTurnoverInEdit(undefined)}></div>
            </>
         }
      </div>
   )

   async function fetchCategories() {

      const response = await makeApiRequest("/Edit/API/Categories", 'GET');

      if (response.status === 403 || response.status === 401) {
         return navigate('/');
      }
      if (response.status === 200) {
         const data: CategoriesT[] = await response.json();
         setCategories(data);
      } else {
         console.log(response.statusText);
      }

   }

   async function fetchTurnovers() {
      const response = await makeApiRequest(
         `/Edit/API/Turnovers?from=${turnoverStartDate}&to=${turnoverEndDate}&category_id=${turnoverCategory}`,
         'GET'
      );

      if (response.status === 403 || response.status === 401) {
         return navigate('/');
      }
      if (response.status === 200) {
         const data: TurnoversT[] = await response.json();
         setTurnoverEntries(data);
      }
   }

   async function makeCategoryEdit(obj: { original: CategoriesT, changes: EditedCategoryT }) {

      setCategoryInEdit(undefined);

      /*
      if (!obj.changes.newColor && !obj.changes.newName) {
         return;
      }

      const name = obj.changes.newName;
      const color = obj.changes.newColor;

      const response = await makeApiRequest(
         `/Edit/API/EditCategory?id=${obj.original.id}${name ? '&name=' + name : ''}${color ? '&color=' + color.slice(1) : ''}`,
         'PUT'
      );

      setCategoryInEdit(undefined);
      if (response.status === 200) {
         navigate('/Home');
      } else {
         showInfo(response.statusText, 'alert');
      }
      */
   }

   function makeTurnoverEdit(obj: { original: TurnoversT, changes: EditedTurnoverT }) {

      setTurnoverInEdit(undefined);
   }

   function deleteTurnover(obj: TurnoversT) {
      console.log('delete turnover: ', obj);
   }

   function showInfo(message: string, icon?: 'alert' | 'info' | 'network', iconColor?: 'red') {
      setInfoMessage(<InfoMessage icon={icon} message={message} iconColor={iconColor} pressedClose={() => setInfoMessage(undefined)} />)
   }
} export default Edit;