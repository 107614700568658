import { CategoriesT } from '../../Views/Edit/Edit';
import './EditCategoryInfoCardStyle.css';

interface EditCategoryInfoCardProps {
   details: CategoriesT
   handleClick: (obj: CategoriesT) => void
}

function EditCategoryInfoCard({ details, handleClick }: EditCategoryInfoCardProps) {

   return (
      <div key={details.id} className="EditCategoryInfoCard InfoCard">
         <h4>{details.name}</h4>
         <div style={{ backgroundColor: '#' + details.color }}></div>
         <button onClick={() => handleClick(details)} className="primary btn">Edit</button>
      </div>
   );
} export default EditCategoryInfoCard;