import React, { useState } from 'react';
import './DateSelectionStyle.css';

interface DateSelectionProps {
   handleChange: (date: string) => void
}

function DateSelection({ handleChange }: DateSelectionProps) {

   const [isDateInputVisible, setDateInputVisible] = useState(false);

   const [currentDate, setCurrentDate] = useState<string | undefined>(undefined);

   function handleLabelClick() {
      setDateInputVisible(!isDateInputVisible);
   };

   function handleDateChange(event: React.ChangeEvent<HTMLInputElement>) {
      var date = event.target.value;
      setCurrentDate(date);
      setDateInputVisible(false);
      handleChange(date);
   };

   return (
      <div className='DateSelection'>
         <label htmlFor="dateInput" onClick={handleLabelClick} style={{ cursor: 'pointer' }}>
            <span>
               <img src="assets/calendar.svg" alt="Date" />
               <h4>{currentDate ? currentDate : 'Today'}</h4>
            </span>
         </label>
         <span>
            <input
               type="date"
               name="datetime"
               id="dateInput"
               placeholder='Now'
               onChange={handleDateChange}
               style={{ visibility: isDateInputVisible ? 'visible' : 'hidden' }}
            />
         </span>
      </div>
   );
};

export default DateSelection;
