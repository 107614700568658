import { useEffect, useState } from 'react';
import './EditCardStyle.css'
import Selection from '../Selection/Selection';
import { CategoriesT, EditedTurnoverT, TurnoversT } from '../../lib/types';

interface EditCardProp {
   editData: TurnoversT;
   categoryList: CategoriesT[];
   editsMade: (obj: { original: TurnoversT, changes: EditedTurnoverT }) => void;
   deleteTurnover: (obj: TurnoversT) => void;
}

function EditCard({ editData, categoryList, editsMade, deleteTurnover }: EditCardProp) {

   const [newCategory, setNewCategory] = useState<number>(0);
   const [newDate, setNewDate] = useState<string>("");
   const [newAmount, setNewAmount] = useState<number>(0);


   useEffect(() => {
      setNewCategory(editData.category_id);
      setNewDate(editData.dateTime);
      setNewAmount(editData.amount);
   }, [editData]);

   return (
      <div className="EditCard">
         <div className="content">
            <div>
               <h4>Category </h4>
               <Selection options={categoryList} onChange={setNewCategory} defaultSelected={editData.category_id} />
            </div>
            <div>
               <h4>Date</h4>
               <input type="date" name="" id="" value={newDate.slice(0, 10)}
                  onChange={(e) => setNewDate(e.currentTarget.value)}
               />
            </div>
            <h4>Amount</h4>
            <div>
               <div className='EditRow'>
                  <input
                     type="number"
                     inputMode='numeric'
                     name="number"
                     id=""
                     min={0}
                     value={newAmount === 0 ? '' : newAmount}
                     onChange={e => setNewAmount(_ => Number(e.target.value))}
                  />
                  <p>€</p>
               </div>
            </div>
            <button
               className='primary btnB'
               onClick={() => updateTurnover()}
            >
               Save
            </button>
            <button
               className='tertiary btn'
               onClick={() => handleDelete()}
            >
               Delete
            </button>
         </div>
      </div>
   );

   function updateTurnover() {

      var changesObj: EditedTurnoverT = {};

      if (newCategory !== editData.category_id) {
         changesObj.newCategory = newCategory;
      }
      if (newDate !== editData.dateTime) {
         changesObj.newDate = newDate;
      }
      if (newAmount !== editData.amount) {
         changesObj.newAmount = newAmount;
      }

      editsMade({ original: editData, changes: changesObj });
   }

   function handleDelete() {
      const confimDel = window.confirm(`Are you shure you want to delete the Turnover: \n${editData.category_name}, ${editData.dateTime.slice(0, 10)}, ${editData.amount}€`)
      if (confimDel) deleteTurnover(editData);
   }

} export default EditCard;
