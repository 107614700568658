interface BarT {
   title: string;
   subBars: {
      category_name: string;
      category_color: string;
      total_amount: number;
   }[];
   max: number;
}

interface BarChartBarProps {
   barData: BarT;
}

function BarChartBar({ barData }: BarChartBarProps) {
   if (!barData) return <></>; // Return empty if barData is undefined

   return (
      <div className="BarChartBar">
         <div className="smalText barTitle">{barData.title}</div>
         <div className="bar">
            {barData.subBars.map((subBar, index) =>
               subBar.total_amount > 0 && (
                  <div
                     className={subBar.category_name}
                     style={{
                        height: (subBar.total_amount / barData.max) * 100 + '%',
                        backgroundColor: '#' + subBar.category_color
                     }}
                     key={index}
                     onClick={() => console.log(barData.title, subBar.category_name, subBar.total_amount)}
                  ></div>
               )
            )
            }
         </div>
      </div>
   );
}

export default BarChartBar;
