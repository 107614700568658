import { TurnoversT } from '../../Views/Edit/Edit';
import './EditTurnoverInfoCardStyle.css';

interface EditCategoryInfoCardProps {
   details: TurnoversT[]
   handleClick: (obj: TurnoversT) => void
}

function EditCategoryInfoCard({ details, handleClick }: EditCategoryInfoCardProps) {

   return (
      <div className="Edit-turnovers">
         {details.map((turnover, index) => (
            <div key={turnover.id} className="EditTurnoverInfoCard InfoCard">
               <h5>{turnover.category_name}</h5>
               <p>{dateTime(turnover.dateTime)}</p>
               <p>{turnover.amount + '€'}</p>
               <button onClick={() => handleClick(turnover)} className="primary btn">Edit</button>
            </div>
         ))}
      </div>
   );

   function dateTime(date: string) {
      // TODO
      // if (date.slice(11, 16) != "00:00:00") {
      //    return date.slice(0, 10) + ' ' + date.slice(11, 16);
      // }
      return date.slice(0, 10);
   }
} export default EditCategoryInfoCard;