import { useEffect, useRef, useState } from "react";
import BarChartBar from "./BarChartBar";
import './BarChartStyle.css';

interface ChartDataT_D {
   category_id: number,
   category_name: string,
   total_amount: number,
   category_color: string,
   day: string, // YYYY-mm-dd
}
interface ChartDataT_W {
   category_id: number,
   category_name: string,
   category_color: string,
   total_amount: number,
   year: number,
   week_number: number,
}
interface ChartDataT_M {
   category_id: number,
   category_name: string,
   category_color: string,
   total_amount: number,
   month: string // YYYY-mm
}
interface ChartDataT_Y {
   category_id: number,
   category_name: string,
   category_color: string,
   total_amount: number,
   year: string // YYYY-mm
}

interface BarChartProps {
   data: (ChartDataT_D | ChartDataT_W | ChartDataT_M | ChartDataT_Y)[]
}

interface BarT {
   title: string,
   subBars: {
      category_name: string,
      category_color: string,
      total_amount: number
   }[],
   max: number;
}

interface BarChartDataT {
   barCharts: BarT[],
   kategories: string[][]
}

function roundToNextMultiple(number: number, multiple: number): number {
   return Math.ceil(number / multiple) * multiple;
}

function formatToBars(data: any[]): BarChartDataT {

   var bars: Map<string, { category_name: string, category_color: string, total_amount: number }[]> = new Map();
   var max = 0;

   if (data.length === 0) return { barCharts: [], kategories: [] };

   const kategories = new Map<string, string>();

   if ('month' in data[0]) {
      data.map((entry: ChartDataT_M) => {
         max = Math.max(max, entry.total_amount);
         kategories.set(entry.category_name, entry.category_color);
         var inMonth = bars.get(entry.month) || [];
         const newEntry = {
            category_name: entry.category_name,
            category_color: entry.category_color,
            total_amount: entry.total_amount
         };
         inMonth.push(newEntry);
         bars.set(entry.month, inMonth);
      });
      const months = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];
      max = roundToNextMultiple(max, 100);
      return {
         barCharts: Array.from(bars.entries()).map(([title, subBars]) => (
            { title: months[Number(title.substring(5)) - 1], subBars, max })
         ),
         kategories: Array.from(kategories)
      };
   }
   if ('day' in data[0]) {
      data.map((entry: ChartDataT_D) => {
         max = Math.max(max, entry.total_amount);
         kategories.set(entry.category_name, entry.category_color);
         const day = entry.day.substring(0, 10)
         var inMonth = bars.get(day) || [];
         const newEntry = {
            category_name: entry.category_name,
            category_color: entry.category_color,
            total_amount: entry.total_amount
         };
         inMonth.push(newEntry);
         bars.set(day, inMonth);
      });
      max = roundToNextMultiple(max, 10);
      return {
         barCharts: Array.from(bars.entries()).map(([title, subBars]) => (
            { title: new Date(title).toLocaleDateString('en-US', { weekday: 'short' }), subBars, max })
         ),
         kategories: Array.from(kategories)
      };
   }
   if ('week_number' in data[0]) {
      data.map((entry: ChartDataT_W) => {
         max = Math.max(max, entry.total_amount);
         kategories.set(entry.category_name, entry.category_color);
         var inMonth = bars.get(`KW ${entry.week_number}`) || [];
         const newEntry = {
            category_name: entry.category_name,
            category_color: entry.category_color,
            total_amount: entry.total_amount
         };
         inMonth.push(newEntry);
         bars.set(`KW ${entry.week_number}`, inMonth);
      });
      max = roundToNextMultiple(max, 50);
      return {
         barCharts: Array.from(bars.entries()).map(([title, subBars]) => (
            { title, subBars, max })
         ),
         kategories: Array.from(kategories.entries())
      };
   }
   if ('year' in data[0]) {
      data.map((entry: ChartDataT_Y) => {
         max = Math.max(max, entry.total_amount);
         kategories.set(entry.category_name, entry.category_color);
         var inMonth = bars.get(entry.year) || [];
         const newEntry = {
            category_name: entry.category_name,
            category_color: entry.category_color,
            total_amount: entry.total_amount
         };
         inMonth.push(newEntry);
         bars.set(entry.year, inMonth);
      });
      max = roundToNextMultiple(max, 200);
      return {
         barCharts: Array.from(bars.entries()).map(([title, subBars]) => (
            { title, subBars, max })
         ),
         kategories: Array.from(kategories)
      };
   }

   return { barCharts: [], kategories: [] };
}

function BarChart({ data }: BarChartProps) {

   const [barChartData, setBarChartData] = useState(formatToBars(data));
   const max = Math.max(...barChartData.barCharts.map(bar => bar.max));

   return (
      <div className="BarChart" id="chart1">
         <div className="chart-container">
            <div className="scale">
               <div className="scale-labels">
                  {Array.from({ length: 11 }, (_, i) => (
                     <span key={i}>{Math.round(max * (1 - 0.1 * i)) + '€'}</span>
                  ))}
               </div>
               <div className="scale-lines-wrapper">
                  {Array.from({ length: 11 }, (_, i) => (
                     <div key={i} className="scale-line"></div>
                  ))}
               </div>
            </div>
            <div className="chart">
               {
                  barChartData.barCharts.map((bar, index) =>
                     <BarChartBar key={index} barData={bar} />
                  )
               }
            </div>
         </div>
         <div className="legend">
            {
               barChartData.kategories.map((k, index) =>
                  <div
                     className="Legend-element"
                     key={index}>
                     <div style={{ backgroundColor: '#' + k[1] }} className="color"></div>
                     <p>{k[0]}</p>
                  </div>
               )
            }
         </div>
      </div>
   );
}
export default BarChart;