import './InfoCardStyle.css'

interface InfoCardProps {
   content: (string | number)[]
   align: 's' | 'c' | 'e' | 'se' | 'sc' | 'ce' | 'sce'
   spaceing?: 1 | 2 | 3
}

function InfoCard({ content, align = 's', spaceing = 3 }: InfoCardProps) {

   var labels;

   if (spaceing === 1) {
      labels = content.map((elm, index) => (
         <p key={index} className={`InfoCard-align-${align} s${spaceing}`} > {String(elm)}</p>
      ));

   } else {
      labels = content.map((elm, index) => (
         <h4 key={index} className={`InfoCard-align-${align} s${spaceing}`} > {String(elm)}</h4 >
      ));
   }

   return (
      <div className={`InfoCard s${spaceing}`}>
         {labels}
      </div>
   );
};

export default InfoCard;
