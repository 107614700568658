import { useRef } from 'react';
import './NumPadStyle.css'

interface NumPadProps {
   onValueChange: (val: number) => void;
}

function NumPad({ onValueChange }: NumPadProps) {

   var padValue = useRef(0);

   function handlePadClick(value: '00' | 'CR' | number): void {

      if (typeof value === 'number' && padValue.current < 100000) {
         padValue.current = padValue.current * 10 + value * 0.01;
         padValue.current = Math.round(padValue.current * 100) / 100;
      } else if (value === '00' && padValue.current < 100000) {
         padValue.current *= 100;
      } else if (value === 'CR') {
         padValue.current *= 0.1;
         padValue.current = Math.floor(padValue.current * 100) / 100;
      }
      onValueChange(padValue.current)
   }

   let buttons: JSX.Element[] = [];

   for (let i = 1; i <= 9; i++) {
      buttons.push(<div key={i} onClick={() => handlePadClick(i)}>{i}</div>);
   }
   buttons.push(<div key={'00'} onClick={() => handlePadClick('00')}>00</div>)
   buttons.push(<div key={0} onClick={() => handlePadClick(0)}>0</div>)
   buttons.push(<div key={'CR'} onClick={() => handlePadClick('CR')}>CR</div>)

   return (
      <div className='NumPad'>
         {buttons}
      </div>
   )
};

export default NumPad;
