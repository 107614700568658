import './SimpleButtonStyle.css'

interface SimpleButtonProps {
   title: string
   value?: any
   active: boolean
   handleClick: (value: any) => void;
}

function SimpleButton({ title, handleClick, value, active }: SimpleButtonProps) {

   if (value === undefined) {
      value = title;
   }

   return (
      <button
         className={(active) ? "SimpleButton active" : "SimpleButton"}
         onClick={() => handleClick(value)}
      >
         {title}
      </button>
   )
};

export default SimpleButton;
