import { useNavigate } from "react-router-dom";
import { StatChartT } from "./types";

export async function makeApiRequest(subLink: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE', body?: any) {
   try {
      const options = {
         method: method,
         headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
         },
         ...(method === 'POST' && { body: JSON.stringify(body) })
      }
      const response = await fetch(`https://philipkurth.de:3001${subLink}`, options);

      if (response.status === 401 || response.status === 403) {
         localStorage.removeItem('accessToken');
      }

      return response;
   } catch (err) {
      console.log('Error making API request ', err);
   }
   return new Response(null, { status: 500 });
}

export function statSUM(data: StatChartT[]) {
   let result = new Map<string, number>();

   data.map((entry: any) => {
      let a = result.get(entry.category_name) || 0;
      result.set(entry.category_name, a + entry.total_amount);
      return null;
   })

   return Array.from(result.entries()).map(entry => [entry[0], String(entry[1]) + '€']);

}
export function statAVG(data: StatChartT[]) {
   let result = new Map<string, { sum: number; count: number }>();
   data.forEach((entry) => {
      let currentValue = result.get(entry.category_name) || { sum: 0, count: 0 };
      currentValue.sum += entry.total_amount;
      currentValue.count += 1;
      result.set(entry.category_name, currentValue);
   });
   return Array.from(result.entries()).map(([key, value]) => [key, `${(value.sum / value.count).toFixed(2)}€`]);
}
export function statMIN(data: StatChartT[]) {
   let result = new Map<string, number>();

   data.forEach((entry) => {
      let currentValue = result.get(entry.category_name);
      if (currentValue === undefined) {
         result.set(entry.category_name, entry.total_amount);
      } else {
         result.set(entry.category_name, Math.min(currentValue, entry.total_amount));
      }
   });

   return Array.from(result.entries()).map(([key, value]) => [key, `${value}€`]);
}
export function statMAX(data: StatChartT[]) {
   let result = new Map<string, number>();

   data.forEach((entry) => {
      let currentValue = result.get(entry.category_name);
      if (currentValue === undefined) {
         result.set(entry.category_name, entry.total_amount);
      } else {
         result.set(entry.category_name, Math.max(currentValue, entry.total_amount));
      }
   });

   return Array.from(result.entries()).map(([key, value]) => [key, `${value}€`]);
}
export function calculateDate(span: string) {
   const today = new Date();
   let resultDate = new Date(today);

   if (span === '7D') {
      resultDate.setDate(today.getDate() - 6);
   } else if (span === '14D') {
      resultDate.setDate(today.getDate() - 13);
   } else if (span === '1M') {
      resultDate.setMonth(today.getMonth() - 1);
      // resultDate.setDate(1);
   } else if (span === '3M') {
      resultDate.setMonth(today.getMonth() - 2);
      resultDate.setDate(1);
      today.setDate(1);
   } else if (span === '6M') {
      resultDate.setMonth(today.getMonth() - 5);
      resultDate.setDate(1);
      today.setDate(1);
   } else if (span === '1Y') {
      resultDate.setFullYear(today.getFullYear() - 1);
      resultDate.setMonth(today.getMonth() + 1);
      resultDate.setDate(1);
      today.setDate(1);
   } else if (span === 'ALL') {
      resultDate = new Date('2023-01-01');
      today.setDate(1);
      today.setMonth(0);
   } else {
      throw new Error('Unknown span');
   }
   let startDate = resultDate.toISOString().substring(0, 10);
   let endDate = today.toISOString().substring(0, 10);
   return { startDate, endDate };
}

export function getLastMonth(): string {
   var now = new Date();
   now.setMonth(now.getMonth() - 1);
   if (now.getMonth() === -1) {
      now.setFullYear(now.getFullYear() - 1);
      now.setMonth(11);
   }
   return now.toISOString().slice(0, 10);
}

export function loadOrDefault(key: string, standard: any) {
   const storageString = localStorage.getItem(key);

   if (storageString) {
      try {
         return JSON.parse(storageString);
      } catch (e) {
         return storageString;
      }
   }
   return standard;
}