import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Views/Home/Home';
import Login from './Views/Login/Login';
import Add from './Views/Add/Add';
import Edit from './Views/Edit/Edit';

function App() {

   return (
      <div className="App">
         <BrowserRouter>
            <Routes>
               <Route path='/' element={<Login />} />
               <Route path='/Home' element={<Home />} />
               <Route path='/Add' element={<Add />} />
               <Route path='/Edit' element={<Edit />} />
            </Routes>
         </BrowserRouter>
      </div>
   );
}

export default App;
