import { useEffect, useMemo, useRef, useState } from 'react';
import './SelectionStyle.css';

interface SelectionProps {
   title?: string,
   options: { name: string; id: number }[] | undefined,
   defaultSelected?: number,
   onChange: (optionId: number) => void
}

function Selection({ title, options, defaultSelected, onChange }: SelectionProps) {
   const [selected, setSelected] = useState<number | undefined>(undefined);
   const [optionsVisible, setOptionsVisible] = useState<boolean>(false);

   var divWidth = document.getElementById('Selection')?.offsetWidth || 350;

   // Handle option selection
   function handleChange(index: number) {
      if (!options) return;
      const selectedOption = options[index];
      setSelected(selectedOption.id); // Save selected id
      setOptionsVisible(false);
      onChange(selectedOption.id); // Notify parent of the selected id
   }

   // Initialize the selected option when options change
   useEffect(() => {
      if (options && options.length > 0) {
         setSelected(defaultSelected || options[0].id); // Set the first option as selected by default
         onChange(defaultSelected || options[0].id); // Notify parent of the initial selection
      }
   }, [options, onChange]);

   return (
      <div className="Selection" >
         {title && <h3>{title}</h3>}

         <div onClick={() => setOptionsVisible(!optionsVisible)} id='Selection'>
            <div className="currentSelection">
               <span>
                  {selected !== undefined
                     ? options?.find(option => option.id === selected)?.name
                     : 'Loading...'}
               </span>
               <img
                  src={`assets/${optionsVisible ? 'upArrow' : 'downArrow'}.svg`}
                  alt="toggle"
               />
            </div>

            {optionsVisible && (
               <div className="Selection-option-div" style={{ width: divWidth + 'px' }}>
                  {options && options.map((option, index) => (
                     <div key={option.id} onClick={() => handleChange(index)}>
                        <img
                           className={selected === option.id ? 'active' : ''}
                           src="assets/checkmark.svg"
                           alt="choose"
                        />
                        <span>{option.name}</span>
                     </div>
                  ))}
               </div>
            )}
         </div>
      </div>
   );
}

export default Selection;
