import './Home.css'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoadOrDefault } from '../../lib/hooks';
import { makeApiRequest, statSUM, statMIN, statAVG, statMAX, calculateDate, loadOrDefault } from '../../lib/utils';
import { TurnoverResT, StatChartT } from '../../lib/types';
import { timeSpanButtons, funcStatsButtons } from '../../lib/constrains'

import InfoCardList from '../../Components/InfoCard/InfoCardList';
import NaviBar from '../../Components/NaviBar/NaviBar';
import SimpleButtonGroup from '../../Components/SimpleButtonGroup/SimpleButtonGroup';
import BarInfoCard from '../../Components/InfoCard/BarInfoCard';
import InfoCard from '../../Components/InfoCard/InfoCard';
import BarChart from '../../Components/BarChart/BarChart';

function Home() {

   const navigate = useNavigate();

   const [lastTurnoverDate, setLastTurnoverDate] = useState<Date>(new Date());
   const [turnoverLoading, setTurnoverLoading] = useState(false);
   const [hasMoreTurnovers, setHasMoreTurnovers] = useState<boolean>(true);
   const [turnovers, setTurnovers] = useState<TurnoverResT[]>(
      loadOrDefault('turnovers', [{ month: "Loading...", newLastDate: '', data: [] }]
      ));
   const [monthSum, setmonthSum] = useLoadOrDefault('monthSum', { month: "loading...", amount: 0, tax_free: 538 });
   const [statChartData, setStatChartData] = useLoadOrDefault('chartData', []);
   const [stats, setStats] = useLoadOrDefault('stats', [["loading...", "0€"], ["loading...", "0€"], ["loading...", "0€"]]);
   const [currentInterval, setCurrentInterval] = useLoadOrDefault('currentInterval', { interval: "M", span: "3M" });
   const [currentStatsFunction, setCurrentStatsFunction] = useLoadOrDefault('statsFunc', "SUM");

   useEffect(() => {
      getChartAndStatData(currentInterval);
      fetchMonthSum();
      fetchNextTurnover(lastTurnoverDate)
   }, [1]);

   const handleResize = () => {
      const chartElement = document.getElementsByClassName('chart')[0];
      if (chartElement) {
         if (chartElement.scrollWidth > chartElement.clientWidth) {
            chartElement.id = 'v';
         } else chartElement.id = '';
      }
   };

   useEffect(() => {
      localStorage.setItem('monthSum', JSON.stringify(monthSum));
   }, [monthSum]);
   useEffect(() => {
      localStorage.setItem('chartData', JSON.stringify(statChartData));
      handleResize();
   }, [statChartData]);
   useEffect(() => {
      localStorage.setItem('stats', JSON.stringify(stats));
   }, [stats]);
   useEffect(() => {
      localStorage.setItem('currentInterval', JSON.stringify(currentInterval));
   }, [currentInterval]);
   useEffect(() => {
      localStorage.setItem('statsFunc', JSON.stringify(currentStatsFunction));
   }, [currentStatsFunction]);

   return (
      <>
         <NaviBar />
         <div className="Home">
            {statChartData.length > 0 ?
               <BarChart data={statChartData} />
               :
               <div className="dummyChartDiv" style={{ height: '424px' }}></div>
            }
            <SimpleButtonGroup
               buttons={timeSpanButtons}
               active={timeSpanButtons.findIndex(btn => btn.title === currentInterval.span)}
               handleClick={getChartAndStatData}
            />

            <InfoCardList
               align='se'
               spaceing={2}
               content={stats}
            />
            <SimpleButtonGroup
               buttons={funcStatsButtons}
               active={funcStatsButtons.findIndex(btn => btn.title === currentStatsFunction)}
               handleClick={changeStatFunctionAndUpdate}
            />
            <p className='smalText'>
               {currentStatsFunction} of the last {currentInterval.span} grouped by {currentInterval.interval}
            </p>
            <InfoCard
               align='se' content={[monthSum.month, `${monthSum.amount}€`]}
            />
            <BarInfoCard
               align='se'
               content={["Tax free", monthSum.tax_free + '€']}
               progress={Math.min(1 - (monthSum.tax_free / 538), 1)}
            />
            <div>
               {turnovers.map((monthList, index) =>
                  <div key={index}>
                     <h2 key={'-' + index}>{monthList.month}</h2>
                     <InfoCardList key={'--' + index} spaceing={1} align='sce' content={monthList.data} />
                  </div>
               )}
               <button
                  className={hasMoreTurnovers ? 'primary btnB' : 'primary btnB dissabled'}
                  onClick={() => fetchNextTurnover(lastTurnoverDate)}
               >
                  Load More
               </button>
            </div>
         </div >
      </>
   )

   async function fetchNextTurnover(date: Date) {

      if (!hasMoreTurnovers || turnoverLoading) return;
      console.log('fetch new Turnovers');

      setTurnoverLoading(true);

      const response = await makeApiRequest(`/Home/API/Turnovers?date=${date.getFullYear()}-${(date.getMonth() + 1)}`, 'GET');
      const data: TurnoverResT = await response.json();

      if (response.status === 403 || response.status === 401) {
         return navigate('/');
      }
      if (response.status === 200) {
         if (data.data.length > 0) {
            if (turnovers[0].newLastDate === '') {
               setTurnovers([data])
               localStorage.setItem('turnovers',
                  JSON.stringify([{ month: data.month, newLastDate: '', data: data.data }])
               );
            }
            else setTurnovers(prev => [...prev, data]);
            setLastTurnoverDate(new Date(data.newLastDate));

         } else setHasMoreTurnovers(false);
      } else console.log('bad', response);
      setTurnoverLoading(false);
   }

   async function fetchMonthSum() {
      console.log('fetch Month Data')

      const response = await makeApiRequest('/Home/API/MonthSum', 'GET');

      if (response.status === 403 || response.status === 401) {
         return navigate('/');
      }
      if (response.status === 200) {
         const data = await response.json();
         setmonthSum(data);
      } else {
         console.log('Error', response);
      }
   }

   async function fetchCategoriesInSpan(startDate: string, endDate: string, interval: string) {

      console.log('fetch Chart-Stat-Data');

      const response = await makeApiRequest(`/Home/API/IntervalCategoryTurnover?start=${startDate}&end=${endDate}&interval=${interval}`, 'GET');

      if (response.status === 403 || response.status === 401) {
         return navigate('/');
      }
      if (response.status === 200) {
         const data = await response.json();
         setStatChartData([]);
         setTimeout(() => {
            setStatChartData(data);
            formatStats(data, currentStatsFunction);
         }, 1);
         console.log(data);
      } else {
         console.log('BAD: ', response);
      }
   }

   function getChartAndStatData(span: { interval: string, span: string }) {
      const { startDate, endDate } = calculateDate(span.span);
      setCurrentInterval(span);
      fetchCategoriesInSpan(startDate, endDate, span.interval)
   }

   function changeStatFunctionAndUpdate(func: string) {
      formatStats(statChartData, func);
      setCurrentStatsFunction(func);
   }

   function formatStats(data: StatChartT[], func: string) {
      switch (func) {
         case 'SUM':
            setStats(statSUM(data));
            break;
         case 'AVG':
            setStats(statAVG(data));
            break;
         case 'MIN':
            setStats(statMIN(data));
            break;
         case 'MAX':
            setStats(statMAX(data));
            break;
         default:
            setStats(statSUM(data));
            break;
      }
   }
}
export default Home
