import './LoginStyle.css';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import { MIN_PASSWORD_LENGTH, MIN_USERNAME_LENGTH } from '../../lib/constrains';
import { useLoadOrDefault } from '../../lib/hooks';
import { makeApiRequest } from '../../lib/utils';

import InfoMessage from '../../Components/InfoMessage/InfoMessage';

function Login() {
   const [username, setUsername] = useLoadOrDefault('username', '');
   const [password, setPassword] = useState('');
   const [lastPasswordAC, setLastPasswordAC] = useState('');

   const [infoMessage, setInfoMessage] = useState<JSX.Element>();

   const navigate = useNavigate();

   useEffect(() => {
      if (lastPasswordAC.length === 0 &&
         (password.length - lastPasswordAC.length) >= MIN_PASSWORD_LENGTH) {
         handleLogin();
      }
      setLastPasswordAC(password);
   }, [password])

   return (
      <>
         {infoMessage}
         <div className="Login">
            <div className="username">
               <ReactSVG src='assets/user.svg' className='icon' />
               <input
                  type="text"
                  name='username'
                  autoFocus={username === ''}
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Username"
               />
            </div>
            <div className="password">
               <ReactSVG src='assets/lock.svg' className='icon' />
               <input
                  type="password"
                  name='password'
                  autoFocus={username !== ''}
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
               />
            </div>
            <div className='button'>
               <button className='primary btnB' type="submit" onClick={handleLogin}>Login</button>
            </div>
         </div>

      </>
   );

   async function handleLogin() {

      if (!username || !password) {
         return showInfo('Username or password not set', 'alert');
      }

      if (username.length <= MIN_USERNAME_LENGTH || password.length < MIN_PASSWORD_LENGTH) {
         return showInfo('Your usename or password is to short', 'alert');
      }

      localStorage.setItem('username', username);

      const response = await makeApiRequest('/login', 'POST', { username, password });

      if (response.status === 200) {
         const data = await response.json();
         localStorage.setItem('accessToken', data.token);
         navigate('/Home');

      } else {
         showInfo(response.statusText, 'alert');
      }
   };

   function showInfo(message: string, icon?: 'alert' | 'info' | 'network', iconColor?: 'red') {
      setInfoMessage(<InfoMessage icon={icon} message={message} iconColor={iconColor} pressedClose={() => setInfoMessage(undefined)} />)
   }
}

export default Login;
