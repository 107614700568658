export const MIN_PASSWORD_LENGTH = 8;
export const MIN_USERNAME_LENGTH = 3;

export const timeSpanButtons = [
   { title: '7D', val: { interval: 'D', span: '7D' } },
   { title: '14D', val: { interval: 'D', span: '14D' } },
   { title: '1M', val: { interval: 'W', span: '1M' } },
   { title: '3M', val: { interval: 'M', span: '3M' } },
   { title: '6M', val: { interval: 'M', span: '6M' } },
   { title: '1Y', val: { interval: 'M', span: '1Y' } },
   { title: 'ALL', val: { interval: 'Y', span: 'ALL' } },
]
export const funcStatsButtons = [
   { title: 'SUM' },
   { title: 'AVG' },
   { title: 'MAX' },
   { title: 'MIN' }
]