import { useEffect, useState } from 'react';
import './EditCardStyle.css'
import { CategoriesT, EditedCategoryT } from '../../lib/types';


interface EditCardProp {
   editData: CategoriesT,
   editsMade: (obj: { original: CategoriesT, changes: EditedCategoryT }) => void
}

function EditCard({ editData, editsMade }: EditCardProp) {

   const [newColor, setNewColor] = useState<string>("");
   const [newName, setNewName] = useState<string>("");

   useEffect(() => {
      setNewColor('#' + editData.color);
      setNewName(editData.name);
   }, [editData]);

   return (
      <div className="EditCard">
         <div className="content">
            <div>
               <h4>Category: </h4>
               <input
                  type="text"
                  name="Category"
                  maxLength={12}
                  id=""
                  value={newName}
                  onChange={(e) => setNewName(e.currentTarget.value)}
               />
            </div>
            <div>
               <h4>Color: </h4>
               <div className='EditRow'>
                  <input
                     type="color"
                     name="colorPicker"
                     id=""
                     value={newColor}
                     onChange={(e) => setNewColor((e.currentTarget.value).toUpperCase())}
                  />
                  <p>{newColor}</p>
               </div>
            </div>
            <button
               className='primary btnB'
               onClick={() => updateCategory()}
            >
               Save
            </button>
         </div >
      </div>
   );

   function updateCategory() {

      var changesObj: EditedCategoryT = {};

      if (newName !== editData.name) {
         changesObj.newName = newName;
      }
      if ("color" in editData && newColor !== '#' + editData.color) {
         changesObj.newColor = newColor;
      }
      editsMade({ original: editData, changes: changesObj });
   }

} export default EditCard;
